import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36')
];

export const server_loads = [3,5,6];

export const dictionary = {
		"/[[locale=lang]]/(public)/(home)": [~17,[3,7],[4]],
		"/[[locale=lang]]/(auth)/account": [8,[3,5],[4]],
		"/[[locale=lang]]/(auth)/account/events": [~13,[3,5],[4]],
		"/[[locale=lang]]/(auth)/account/guest-info": [~14,[3,5],[4]],
		"/[[locale=lang]]/(auth)/account/(broker)/payouts": [~10,[3,5,6],[4]],
		"/[[locale=lang]]/(auth)/account/(broker)/payout": [9,[3,5,6],[4]],
		"/[[locale=lang]]/(auth)/account/(broker)/products": [~11,[3,5,6],[4]],
		"/[[locale=lang]]/(auth)/account/(broker)/sales": [~12,[3,5,6],[4]],
		"/[[locale=lang]]/(auth)/account/support": [15,[3,5],[4]],
		"/[[locale=lang]]/(auth)/account/transactions": [~16,[3,5],[4]],
		"/[[locale=lang]]/(public)/become-a-partner": [22,[3,7],[4]],
		"/[[locale=lang]]/(public)/checkout": [~23,[3,7],[4]],
		"/[[locale=lang]]/(public)/contact-us": [24,[3,7],[4]],
		"/[[locale=lang]]/(public)/failure": [~25,[3,7],[4]],
		"/[[locale=lang]]/(public)/faq": [~26,[3,7],[4]],
		"/[[locale=lang]]/(public)/login-challenge": [~27,[3,7],[4]],
		"/[[locale=lang]]/(public)/news": [~28,[3,7],[4]],
		"/[[locale=lang]]/(public)/news/[slug]": [~29,[3,7],[4]],
		"/[[locale=lang]]/(public)/processing": [~30,[3,7],[4]],
		"/[[locale=lang]]/(public)/search": [~31,[3,7],[4]],
		"/[[locale=lang]]/(public)/success/[orderNo]/[id]": [~32,[3,7],[4]],
		"/[[locale=lang]]/(public)/teams": [~33,[3,7],[4]],
		"/[[locale=lang]]/(public)/teams/[...slug]": [~34,[3,7],[4]],
		"/[[locale=lang]]/(public)/test-cart": [~35,[3,7],[4]],
		"/[[locale=lang]]/(public)/tournaments": [~36,[3,7],[4]],
		"/[[locale=lang]]/(public)/[slug=subcategory]": [~20,[3,7],[4]],
		"/[[locale=lang]]/(public)/[slug=subcategory]/[fixture]": [~21,[3,7],[4]],
		"/[[locale=lang]]/(public)/[page]": [19,[3,7],[4]],
		"/[[locale=lang]]/(public)/[...slug]": [~18,[3,7],[4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';